// import { UserContext } from './UserContext'; // Import the UserContext
// import { useContext } from 'react';
// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ children }) => {
//   const {  isLoggedIn, userData } = useContext(UserContext);


//   if (userData && userData?.usertype !== 30 && userData?.usertype !== 20) {
//       return <Navigate to="/not-authorized" />
//   }

//   return isLoggedIn ? children : <Navigate to="/login" />; // Redirect to register or login page if not logged in
// };

// export default PrivateRoute;

import { UserContext } from './UserContext'; // Import the UserContext
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, userData } = useContext(UserContext);
  const location = useLocation();

  const isAdminPanelRoute = [
    '/send/panel',
    '/char/panel',
    '/user/panel',
    '/shop/panel',
    '/shop/add',
    '/shop/view/log',
    '/news/panel',
    '/news/add',
    '/recharge/panel',
    '/recharge/add',
    '/recharge/logs',
    '/iplog/panel',
    '/download/panel',
    '/download/add',
    '/admin/panel',
    '/admin/add',
    '/admin/add/ticket',
    '/admin/log',
    '/admin/user/log',
    '/coupon/panel',
    '/coupon/add',
    '/coupon/log'
  ];
  
  // Regex for paths with dynamic IDs
  const dynamicPathRegex = /^\/shop\/\d+|\/news\/\d+$/;
  
  const isAdminPanel = isAdminPanelRoute.includes(location.pathname) || dynamicPathRegex.test(location.pathname);

  if (!isLoggedIn) {
    // Redirect to login if not logged in
    return <Navigate to="/login" />;
  }

  if (isAdminPanel && (userData?.usertype !== 30 && userData?.usertype !== 20)) {
    // Redirect to not authorized if accessing admin routes without proper usertype
    return <Navigate to="/not-authorized" />;
  }

  if (location.pathname === '/profile/edit/password' || location.pathname === '/shop' || location.pathname === '/recharge' || location.pathname === '/recharge/log') {
    // Allow changing password only if logged in
    return isLoggedIn ? children : <Navigate to="/login" />;
  }

  // For other routes, continue with the normal flow
  return children;
};

export default PrivateRoute;