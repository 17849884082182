import React, { useState, useCallback, useEffect,useContext  } from "react";
import styled from "styled-components";
import { UserContext } from './UserContext'; // Import the UserContext



const FormContainer = styled.div`
  width: 40%;
  background-color: #1c1c1c;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-top: 100px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SuccessMessage = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #2e7d32;
  padding: 15px;
  color: white;
  text-align: center;
`;

const FormField = styled.input`
  width: 90%;
  margin: 0;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  background: #333;
  color: #eee;

  &::placeholder {
    color: grey;
  }

  &.error {
    border-color: #ff6f61;
  }
`;

const ErrorSpan = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ff6f61;
  margin: 5px 0;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background: #273c75;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const SideBySideGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const FormFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const UpdateProfile = React.memo(() => {
  const [values, setValues] = useState({
    fullName: "",
    password: "",
    confirmPassword: "",
    pincode: "",
    confirmPincode: "",
    birthday: ""
  });

  const { userData, setUserData } = useContext(UserContext);
  

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [serverError, setServerError] = useState("");

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value
    }));
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(values);
    if (Object.keys(validationErrors).length === 0) {
      setValid(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updateuser`, {
          method: "PUT", // Use PUT for updating the user
          headers: {
            "Content-Type": "application/json",
            'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
          },
          body: JSON.stringify({
            userId: userData?.username,
            password: values.password,
            pincode: values.pincode,
            birthday: values.birthday,
            fullname: values.fullName,
          }),
        });
        if (response.ok) {
            const updatedUserData = {
                ...userData,
                CompleteName: values.fullName,
              };
              setUserData(updatedUserData); // Update the global userData
              localStorage.setItem('userData', JSON.stringify(updatedUserData));
          setSubmitted(true);
          setServerError("");
        } else {
          const data = await response.json();
          setServerError(data.error || "Update failed");
        }
      } catch (err) {
        console.error("Error updating user:", err);
        setServerError("Update failed");
      }
    } else {
      setValid(false);
    }
    setErrors(validationErrors);
    setSubmitted(true);
  };

  const validateForm = (values) => {
    const errors = {};
    const minAgeDate = new Date();
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 10);
    if (!values.fullName) errors.fullName = "Please enter your full name";
    if (!values.password) errors.password = "Please enter a password";
    if (values.password !== values.confirmPassword) errors.confirmPassword = "Passwords do not match";
    if (!values.pincode) errors.pincode = "Please enter a pincode";
    if (values.pincode !== values.confirmPincode) errors.confirmPincode = "Pincodes do not match";
    if (!values.birthday) {
      errors.birthday = "Please enter your birthday";
    } else {
      const birthdayDate = new Date(values.birthday);
      if (birthdayDate > minAgeDate) {
        errors.birthday = "You must be older than 10 years";
      }
    }

    return errors;
  };

  return (
    <FormContainer>
      <RegisterForm onSubmit={handleSubmit}>
        {submitted && valid && (
          <SuccessMessage>
            <h3>{values.fullName}, your details have been updated successfully!</h3>
          </SuccessMessage>
        )}
        {!valid && (
          <>
            <SideBySideGroup>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  className={errors.fullName ? "error" : ""}
                />
                {errors.fullName && (
                  <ErrorSpan id="full-name-error">{errors.fullName}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="date"
                  placeholder="Birthday"
                  name="birthday"
                  value={values.birthday}
                  onChange={handleInputChange}
                  className={errors.birthday ? "error" : ""}
                />
                {errors.birthday && (
                  <ErrorSpan id="birthday-error">{errors.birthday}</ErrorSpan>
                )}
              </FormFieldContainer>
            </SideBySideGroup>
            <FieldGroup>
              <FormFieldContainer>
                <FormField
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  className={errors.password ? "error" : ""}
                />
                {errors.password && (
                  <ErrorSpan id="password-error">{errors.password}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleInputChange}
                  className={errors.confirmPassword ? "error" : ""}
                />
                {errors.confirmPassword && (
                  <ErrorSpan id="confirm-password-error">
                    {errors.confirmPassword}
                  </ErrorSpan>
                )}
              </FormFieldContainer>
            </FieldGroup>
            <FieldGroup>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={values.pincode}
                  onChange={handleInputChange}
                  className={errors.pincode ? "error" : ""}
                />
                {errors.pincode && (
                  <ErrorSpan id="pincode-error">{errors.pincode}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Confirm Pincode"
                  name="confirmPincode"
                  value={values.confirmPincode}
                  onChange={handleInputChange}
                  className={errors.confirmPincode ? "error" : ""}
                />
                {errors.confirmPincode && (
                  <ErrorSpan id="confirm-pincode-error">
                    {errors.confirmPincode}
                  </ErrorSpan>
                )}
              </FormFieldContainer>
            </FieldGroup>
            <SubmitButton type="submit" disabled={valid}>
              Update Details
            </SubmitButton>
          </>
        )}
      </RegisterForm>
    </FormContainer>
  );
});

export default UpdateProfile;
