import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import UserDetailsTable from '../components/UserDetailsTable'; // Adjust the import as necessary
import UserInfoTable from '../components/UserInfoTable'; // Adjust the import as necessary

import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';

const UserDetailsPage = () => {
    const [userInfo, setUserInfo] = useState([]);
    const { userData } = useContext(UserContext); // Removed setUserData since it's not used

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userinfo?username=${userData.username}`, {
                    headers: {
                      'x-api-key': process.env.REACT_APP_API_KEY, // Include API key only
                    },
                  });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUserInfo(data.user);  // Ensure it's an array
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [userData.username]); // Only fetch when username changes

    return (
        <div>
            <h2>User Details</h2>
            <UserDetailsTable user={userInfo} />
            <h2>User Information</h2>
            <UserInfoTable user={userInfo} />
        </div>
    );
};

export default UserDetailsPage;
