import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheckCircle, faTimesCircle, faUserShield, faUser } from '@fortawesome/free-solid-svg-icons';

// Styled components
const Table = styled.table`
  border-collapse: collapse;
  margin-top: 20px;
  width: 50%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Table shadow */
  transition: all 0.3s ease;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Row hover effect */
    cursor: pointer;
  }
`;

const TableHeader = styled.th`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 12px;
  text-align: left;
  font-weight: 600;
`;

const TableData = styled.td`
  padding: 15px;
  border-bottom: 1px solid #ddd;
  color: white;
  text-align: left;
  font-size: 16px;
  &:first-child {
    font-weight: bold;
    color: white;
    background-color:#2c3e50;
    width: 20%; /* Set a fixed width for the first column */
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

const UpdateButton = styled(Button)`
  background-color: #3498db;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #2980b9;
  }
`;

const GlowText = styled.span`
  color: white;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7); /* Text glow effect */
`;

// Media Query for Responsiveness
const ResponsiveTable = styled.div`
  @media (max-width: 768px) {
    ${Table} {
      width: 100%;
    }

    ${TableData}, ${TableHeader} {
      font-size: 14px;
      padding: 10px;
    }

    ${Button}, ${UpdateButton} {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
`;

export const FaRegistered = styled.i`
  color: #f39c12; /* This is the 'warning' color */
`;

// UserDetailsTable component
const UserDetailsTable = ({ user }) => {
  const navigate = useNavigate();

  return (
    <ResponsiveTable>
      <Table>
        <tbody>
          <TableRow>
            <TableData>Afiliator</TableData>
            <TableData>
              {user.UserAfiliator}
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Created At</TableData>
            <TableData>{user.created_at}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Updated At</TableData>
            <TableData>{user.updated_at}</TableData>
          </TableRow>
        </tbody>
      </Table>
    </ResponsiveTable>
  );
};

export default UserDetailsTable;
